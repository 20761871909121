import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, Typography } from '@mui/material';
// eslint-disable-next-line boundaries/element-types
import { useVersionCheck } from 'entities/object';
import { downloadFile } from 'shared/helpers/downloadFile';
import { getObjectFileUrl } from 'shared/helpers/getFileUrl';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { Col } from 'shared/ui/Col';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { useDeleteObjectDocumentMutation } from '../model/api/DocumentService';
import { Document } from '../model/types/documentSchema';

type DocumentDetailsCardProps = {
  document: Document;
  objectId: number;
  onEditClick: (document: Document) => void;
};

export const DocumentDetailsCard: FC<DocumentDetailsCardProps> = ({
  document,
  objectId,
  onEditClick,
}) => {
  const { t } = useTranslation();
  const [downloadError, setDownloadError] = useState<string>('');
  const [deleteObjectDocument, { isError: isDeleteObjectDocumentError }] =
    useDeleteObjectDocumentMutation();

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: async () => {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        deleteObjectDocument({ objectId, hash: document.hash });
      }
    },
  });

  const handleEditClick = useCallback(() => {
    onEditClick(document);
  }, [onEditClick, document]);

  const handleDownloadDocumentClick = useCallback(() => {
    downloadFile(
      getObjectFileUrl(objectId, document.hash),
      document.name || document.hash
    ).catch((error) => {
      setDownloadError(error.message);
    });
  }, [objectId, document]);

  return (
    <Card sx={{ p: 8 }}>
      {showVersionChangedPopup()}
      <Col spacing={5}>
        <Col spacing={4}>
          <Typography variant="headerXS">{document.name}</Typography>
        </Col>
        {isShowRemoveConfirmation ? (
          <RemovalConfirmationCard
            titleEnding={t('document.card.deleteEnding')}
            onRemoveClick={confirmRemoveAction}
            onCancelClick={hideRemoveConfirmation}
          />
        ) : (
          <Row spacing={5}>
            <Button
              size="small"
              variant="outlined"
              onClick={handleDownloadDocumentClick}
            >
              {t('ui.common.download')}
            </Button>
            <SmallSquareButton icon="edit" onClick={handleEditClick} />
            <SmallSquareButton onClick={showRemoveConfirmation} />
          </Row>
        )}
        {downloadError && (
          <Typography color="error">{t(downloadError)}</Typography>
        )}
        {isDeleteObjectDocumentError && (
          <Typography color="error">
            {t('document.card.error.delete')}
          </Typography>
        )}
      </Col>
    </Card>
  );
};
