import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { EditAboutLotForm, Lot } from 'entities/chess';
import {
  NumberingFormatType,
  editLotZod,
  editMultipleLotZod,
} from 'entities/chess/model/types/lotSchema';
import {
  calculatePricePerMeter,
  calculateTotalPrice,
  calculateTotalArea,
} from 'shared/helpers/fieldsCalculation';
import { getDirtyValues } from 'shared/helpers/getDirtyValues';
import { usePrevious } from 'shared/hooks/usePrevious';
import { TextColor } from 'shared/theme/colors';
import { Col } from 'shared/ui/Col';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { changeLotsNumbers } from './lib/changeLotsNumbers';
import { createLotDataEqualsMap } from './lib/createLotDataEqualsMap';
import { sortByPositions } from './lib/getSelectedLots';

const determineMaskType = (value: string) => {
  // Проверка для каждого типа нумерации
  if (/^\d+$/.test(value)) {
    return NumberingFormatType.number;
  } else if (/^\d+[a-zA-Zа-яА-Я]\d+$/.test(value)) {
    return NumberingFormatType.numberLetterNumber;
  } else if (/^\d+-\d+$/.test(value)) {
    return NumberingFormatType.numberNumber;
  } else if (/^\d+\/\d+$/.test(value)) {
    return NumberingFormatType.numberSlashNumber;
  } else if (/^\d+\/\d+[a-zA-Zа-яА-Я]$/.test(value)) {
    return NumberingFormatType.numberNumberLetter;
  } else if (/^[a-zA-Zа-яА-Я]\d+-\d+$/.test(value)) {
    return NumberingFormatType.letterNumberNumber;
  } else if (/^\d+[a-zA-Zа-яА-Я]$/.test(value)) {
    return NumberingFormatType.numberLetter;
  } else if (/^\d+\.\d+$/.test(value)) {
    return NumberingFormatType.numberPointNumber;
  } else if (/^\d+_\d+$/.test(value)) {
    return NumberingFormatType.numberUnderscoreNumber;
  } else if (/^[a-zA-Zа-яА-Я]\d+$/.test(value)) {
    return NumberingFormatType.letterNumber;
  }

  // Если ни один из форматов не подходит, возвращаем значение по умолчанию
  return NumberingFormatType.number;
};

interface EditBarProps {
  title: string;
  subtitle: string;
  showMergeButton: boolean;
  showSplitButton: boolean;
  showDeleteRiserButton: boolean;
  showDeleteFloorButton: boolean;
  selectedLots: Lot[];
  allLots: Lot[];
  onSaveClick: (changedLots: Lot[]) => void;
  onDisabledChange: (disabled: boolean) => void;
  onMergeClick: () => void;
  onDeleteRiserClick: () => void;
  onDeleteFloorClick: () => void;
  onSplitClick: () => void;
  onCancelClick: () => void;
  isCottageVillageOrTownhouse: boolean;
  onEditBarHeightChange: (height: number) => void;
  isFullscreen: boolean;
  onCloseFullscreen: () => void;
}

const defaultValues = {
  lotType: '',
  lotNumber: '',
  status: '',
  actualSaleDate: '',
  numberOfFloors: '',
  numberOfRooms: '',
  landArea: '',
  totalArea: '',
  kitchenArea: '',
  balconyArea: '',
  decoration: '',
  windowView: '',
  registration: [],
  buyingOptions: [],
  installmentTerms: '',
  panorama: '',
  promotion: '0',
  totalPrice: '',
  pricePerMeter: '',
  lotNumberingFormat: NumberingFormatType.number,
};

export const EditBar: FC<EditBarProps> = ({
  title,
  subtitle,
  showMergeButton,
  showSplitButton,
  showDeleteRiserButton,
  showDeleteFloorButton,
  isCottageVillageOrTownhouse,
  selectedLots,
  allLots,
  onDisabledChange,
  onSaveClick,
  onMergeClick,
  onSplitClick,
  onDeleteRiserClick,
  onDeleteFloorClick,
  onCancelClick,
  onEditBarHeightChange,
  isFullscreen,
  onCloseFullscreen,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [prevChangedLots, setPrevChangedLots] = useState<Map<number, Lot>>(
    new Map()
  );
  const { previous: prevSelectedLots, reset: prevSelectedLotsReset } =
    usePrevious(selectedLots);

  useEffect(() => {
    if (ref.current) {
      onEditBarHeightChange(ref.current.clientHeight);
    }
  });

  const form = useForm<Lot>({
    resolver: zodResolver(
      selectedLots.length === 1 ? editLotZod : editMultipleLotZod
    ),
    mode: 'onSubmit',
    defaultValues,
  });

  const handleClearForm = useCallback(() => {
    form.reset(defaultValues);
  }, [form]);

  const getSelectedLotsSortedForNumbering = useCallback((lots: Lot[]) => {
    return lots.toSorted((lotA, lotB) => sortByPositions(lotA, lotB, false));
  }, []);

  useEffect(() => {
    // если есть измененные лоты проверяем не изменились ли айдишники лотов
    // если изменились то шахматка была пересохранена и нужно сбросить изменения
    if (prevChangedLots.size) {
      const allLotsIds = allLots.map(({ id }) => id);
      const changedLotsIds = Array.from(prevChangedLots.keys());
      const isLotsExists = changedLotsIds.every((changedId) =>
        allLotsIds.includes(changedId)
      );

      if (!isLotsExists) {
        setPrevChangedLots(new Map());
        prevSelectedLotsReset([]);
      }
    }
  }, [allLots, prevChangedLots, prevSelectedLotsReset]);

  const applyNumbering = useCallback(
    (lots: Lot[], lotFormData: Lot) => {
      try {
        const isMultipleLots = lots.length > 1;
        const { lotNumber, lotNumberingFormat, ...changedFields } =
          isMultipleLots
            ? (getDirtyValues(
                form.formState.dirtyFields,
                form.getValues() as Record<string, unknown>
              ) as Partial<Lot>)
            : lotFormData;

        let changedLots = getSelectedLotsSortedForNumbering(lots).map(
          (lot) => ({
            ...lot,
            ...changedFields,
          })
        );

        if (lotNumber !== undefined) {
          changedLots = changeLotsNumbers(
            changedLots,
            lotNumber,
            lotFormData.lotNumberingFormat as NumberingFormatType
          );
        }
        return changedLots;
      } catch (error) {
        form.setError('lotNumber', {
          type: 'manual',
          message: 'Нумерацию не соответствует типу нумерации',
        });
        throw new Error('Ошибка при применении нумерации');
      }
    },
    [form, getSelectedLotsSortedForNumbering]
  );

  const applyCalculatedFields = useCallback(
    (changedLots: Lot[], lotFormData: Lot) => {
      const isMultipleLots = changedLots.length > 1;

      if (isMultipleLots) {
        const { lotNumber, lotNumberingFormat, ...changedFields } =
          isMultipleLots
            ? (getDirtyValues(
                form.formState.dirtyFields,
                form.getValues() as Record<string, unknown>
              ) as Partial<Lot>)
            : lotFormData;
        const calculatedFiledsNames = [
          'totalArea',
          'pricePerMeter',
          'totalPrice',
        ];

        const changedCalculatedFields = Object.keys(changedFields).filter(
          (field) =>
            calculatedFiledsNames.includes(field) &&
            changedFields[
              field as keyof Omit<Lot, 'lotNumber' | 'lotNumberingFormat'>
            ] !== ''
        );

        // если изменили не все три значения, то нужно пересчитать оставшиеся
        if (changedCalculatedFields.length !== calculatedFiledsNames.length) {
          const isTotalAreaChanged =
            changedCalculatedFields.includes('totalArea');
          const isTotalPriceChanged =
            changedCalculatedFields.includes('totalPrice');
          const isPricePerMeterChanged =
            changedCalculatedFields.includes('pricePerMeter');

          changedLots.forEach((lot) => {
            const { totalArea, pricePerMeter, totalPrice } = lot;

            //если изменили общую площадь обновляем цену за метр или общую цену
            if (isTotalAreaChanged) {
              // если изменили общую цену (или не меняли ничего но есть общая цена) обновляем цену за метр
              if (
                isTotalPriceChanged ||
                (!isPricePerMeterChanged && totalPrice)
              ) {
                lot.pricePerMeter = calculatePricePerMeter(
                  totalArea,
                  totalPrice
                ).toString();
              } else if (isPricePerMeterChanged || pricePerMeter) {
                // изменили цену за метр или не меняли но цена за метр есть - считаем общую цену
                lot.totalPrice = calculateTotalPrice(
                  totalArea,
                  pricePerMeter
                ).toString();
              }
            } else if (isPricePerMeterChanged) {
              // Общую площадь не меняли, но меняли цену за метр - считаем общую площадь или общую цену

              // если изменили общую цену (или не меняли ничего но есть общая цена и нету общей площади) обновляем общую площадь
              if (isTotalPriceChanged || (totalPrice && !totalArea)) {
                lot.totalArea = calculateTotalArea(
                  totalPrice,
                  pricePerMeter
                ).toString();
              } else if (totalArea) {
                // не меняли общую цену и нету общей цены, но есть общая площадь - обновляем общую цену
                lot.totalPrice = calculateTotalPrice(
                  totalArea,
                  pricePerMeter
                ).toString();
              }
            } else if (isTotalPriceChanged) {
              // изменили только общую цену - обновляем цену за метр или общую площадь
              if (totalArea) {
                lot.pricePerMeter = calculatePricePerMeter(
                  totalArea,
                  totalPrice
                ).toString();
              } else if (pricePerMeter) {
                lot.totalArea = calculateTotalArea(
                  totalPrice,
                  pricePerMeter
                ).toString();
              }
            }
          });
        }
      }
    },
    [form]
  );

  const updatePrevChangedLots = useCallback(
    (lots: Lot[]) => {
      const lotFormData = form.getValues();
      const newChangedLots = applyNumbering(lots, lotFormData);

      applyCalculatedFields(newChangedLots, lotFormData);

      setPrevChangedLots((prevMap) => {
        const newMap = new Map(prevMap);
        newChangedLots.forEach((lot) => {
          newMap.set(lot.id, lot);
        });
        return newMap;
      });
    },
    [applyCalculatedFields, applyNumbering, form]
  );

  const updateAfterSelectionChanged = useCallback(() => {
    if (prevSelectedLots && prevSelectedLots.length && form.formState.isDirty) {
      updatePrevChangedLots(prevSelectedLots);
    }

    if (!selectedLots.length) {
      handleClearForm();
      return;
    }

    let mergedSelectedLots = selectedLots;

    if (prevChangedLots.size) {
      mergedSelectedLots = selectedLots.map((selectedLot) => {
        if (prevChangedLots.has(selectedLot.id)) {
          return { ...selectedLot, ...prevChangedLots.get(selectedLot.id) };
        }

        return selectedLot;
      });
    }
    const lotsEqualsMap = createLotDataEqualsMap(mergedSelectedLots);

    const lotNumber =
      mergedSelectedLots.length === 1
        ? getSelectedLotsSortedForNumbering(mergedSelectedLots)[0].lotNumber
        : '';

    form.reset({
      lotType: lotsEqualsMap?.lotType ? mergedSelectedLots[0].lotType : '',
      lotNumber,
      lotNumberingFormat: determineMaskType(lotNumber ?? ''),
      status: lotsEqualsMap?.status ? mergedSelectedLots[0].status : '',
      actualSaleDate: lotsEqualsMap?.actualSaleDate
        ? mergedSelectedLots[0].actualSaleDate
        : '',
      numberOfFloors: lotsEqualsMap?.numberOfFloors
        ? mergedSelectedLots[0].numberOfFloors
        : '',
      numberOfRooms: lotsEqualsMap?.numberOfRooms
        ? mergedSelectedLots[0].numberOfRooms
        : '',
      landArea: lotsEqualsMap?.landArea ? mergedSelectedLots[0].landArea : '',
      totalArea: lotsEqualsMap?.totalArea
        ? mergedSelectedLots[0].totalArea
        : '',
      kitchenArea: lotsEqualsMap?.kitchenArea
        ? mergedSelectedLots[0].kitchenArea
        : '',
      balconyArea: lotsEqualsMap?.balconyArea
        ? mergedSelectedLots[0].balconyArea
        : '',
      decoration: lotsEqualsMap?.decoration
        ? mergedSelectedLots[0].decoration
        : '',
      windowView: lotsEqualsMap?.windowView
        ? mergedSelectedLots[0].windowView
        : '',
      registration: lotsEqualsMap?.registration
        ? mergedSelectedLots[0].registration
        : [],
      buyingOptions: lotsEqualsMap?.buyingOptions
        ? mergedSelectedLots[0].buyingOptions
        : [],
      installmentTerms: lotsEqualsMap?.installmentTerms
        ? mergedSelectedLots[0].installmentTerms
        : '',
      panorama: lotsEqualsMap?.panorama ? mergedSelectedLots[0].panorama : '',
      promotion: lotsEqualsMap?.promotion
        ? mergedSelectedLots[0].promotion
        : '',
      totalPrice: lotsEqualsMap?.totalPrice
        ? mergedSelectedLots[0].totalPrice
        : '',
      pricePerMeter: lotsEqualsMap?.pricePerMeter
        ? mergedSelectedLots[0].pricePerMeter
        : '',
      type: lotsEqualsMap?.type ? mergedSelectedLots[0].type : 'flat',
    });
  }, [
    form,
    getSelectedLotsSortedForNumbering,
    handleClearForm,
    prevChangedLots,
    prevSelectedLots,
    selectedLots,
    updatePrevChangedLots,
  ]);

  useEffect(() => {
    // effect for selectedLots changed -> update form
    updateAfterSelectionChanged();
  }, [selectedLots, updateAfterSelectionChanged]);

  useEffect(() => {
    const subscription = form.watch(() => {
      const buyingOptionValue = form.getValues('buyingOptions');
      const installmentTermsValue = form.getValues('installmentTerms');

      if (
        buyingOptionValue &&
        !buyingOptionValue.includes('installments') &&
        installmentTermsValue
      ) {
        form.setValue('installmentTerms', '');
      }
    });

    return () => subscription.unsubscribe();
  }, [form]);

  const sameLotEnabled = useMemo(
    () =>
      selectedLots.length > 0 &&
      selectedLots.every((lot) => lot.disabled === selectedLots[0].disabled),
    [selectedLots]
  );

  const handleToggleEnableClick = useCallback(() => {
    onDisabledChange(!selectedLots[0].disabled);
  }, [selectedLots, onDisabledChange]);

  const handleSaveAllClick = useCallback(
    (lotFormData?: Lot) => {
      if (!prevChangedLots.size || !lotFormData) {
        console.error('trying save empty data');
        return;
      }

      const changedLots = applyNumbering(selectedLots, lotFormData);

      applyCalculatedFields(changedLots, lotFormData);

      const selectedLotsMap = new Map<number, Lot>();
      changedLots.forEach((lot) => selectedLotsMap.set(lot.id, lot));

      Array.from(prevChangedLots.values()).forEach((prevChangedLot) => {
        if (selectedLotsMap.has(prevChangedLot.id)) {
          selectedLotsMap.set(prevChangedLot.id, {
            ...prevChangedLot,
            ...selectedLotsMap.get(prevChangedLot.id),
          });
        } else {
          selectedLotsMap.set(prevChangedLot.id, prevChangedLot);
        }
      });

      onSaveClick(Array.from(selectedLotsMap.values()));
    },
    [
      applyCalculatedFields,
      applyNumbering,
      onSaveClick,
      prevChangedLots,
      selectedLots,
    ]
  );

  const cancelChanges = () => {
    setPrevChangedLots(new Map());
    prevSelectedLotsReset([]);
    handleClearForm();
  };

  const handleSaveClick = useCallback(
    (lotFormData?: Lot) => {
      if (!lotFormData) {
        console.error('trying save empty data');
        return;
      }

      const changedLots = applyNumbering(selectedLots, lotFormData);

      applyCalculatedFields(changedLots, lotFormData);

      onSaveClick(changedLots);
    },
    [applyCalculatedFields, applyNumbering, onSaveClick, selectedLots]
  );

  const buttons = useMemo(() => {
    if (!selectedLots.length) {
      return null;
    }
    const result: JSX.Element[] = [];

    if (!prevChangedLots.size) {
      result.push(
        <Button
          key="save"
          size="small"
          color="secondary"
          onClick={form.handleSubmit(handleSaveClick)}
        >
          {t('ui.common.save')}
        </Button>
      );
    } else {
      result.push(
        <Button
          key="saveAll"
          size="small"
          color="secondary"
          onClick={form.handleSubmit(handleSaveAllClick)}
        >
          {t('ui.common.saveAll')}
        </Button>
      );
      result.push(
        <Button
          key="cancel"
          size="small"
          variant="outlined"
          onClick={cancelChanges}
        >
          {t('ui.common.cancel')}
        </Button>
      );
    }

    showMergeButton &&
      result.push(
        <Button
          key="merge"
          size="small"
          variant="outlined"
          onClick={onMergeClick}
        >
          {t('chess.dialog.merge')}
        </Button>
      );

    showSplitButton &&
      result.push(
        <Button
          key="split"
          size="small"
          variant="outlined"
          onClick={onSplitClick}
        >
          {t('chess.dialog.split')}
        </Button>
      );

    result.push(
      <Button
        key="clear"
        size="small"
        variant="outlined"
        onClick={handleClearForm}
      >
        {t('chess.dialog.clear')}
      </Button>
    );

    showDeleteFloorButton &&
      result.push(
        <Button
          key="deleteFloor"
          size="small"
          variant="outlined"
          onClick={onDeleteFloorClick}
        >
          {t('chess.dialog.deleteFloor')}
        </Button>
      );

    showDeleteRiserButton &&
      result.push(
        <Button
          key="deleteRiser"
          size="small"
          variant="outlined"
          onClick={onDeleteRiserClick}
        >
          {t('chess.dialog.deleteRiser')}
        </Button>
      );

    sameLotEnabled &&
      result.push(
        <Button
          key="toggleEnable"
          size="small"
          variant="outlined"
          onClick={handleToggleEnableClick}
        >
          {selectedLots[0]?.disabled
            ? t('chess.dialog.turnOn')
            : t('chess.dialog.turnOff')}
        </Button>
      );

    return result;
  }, [
    cancelChanges,
    form,
    handleClearForm,
    handleSaveAllClick,
    handleSaveClick,
    handleToggleEnableClick,
    onDeleteFloorClick,
    onDeleteRiserClick,
    onMergeClick,
    onSplitClick,
    prevChangedLots.size,
    sameLotEnabled,
    selectedLots,
    showDeleteFloorButton,
    showDeleteRiserButton,
    showMergeButton,
    showSplitButton,
    t,
  ]);

  return (
    <Card ref={ref}>
      <CardContent>
        <Col spacing={8}>
          <Row sx={{ justifyContent: 'space-between', gap: 5 }}>
            <Row
              spacing={5}
              sx={{ maxWidth: { xs: 270, sm: 400, md: 600, lg: 1000 } }}
            >
              {title ? (
                <Col>
                  <Typography variant={'accentM'}>{title}</Typography>
                  <Typography variant="paragraphS" color={TextColor.Secondary}>
                    {subtitle}
                  </Typography>
                </Col>
              ) : (
                <Typography variant={'accentM'}>
                  {t('chess.dialog.selectLot')}
                </Typography>
              )}
              {buttons && !isFullscreen && (
                <Divider
                  orientation="vertical"
                  sx={{ visibility: { xs: 'hidden', sm: 'visible' } }}
                />
              )}
              {buttons}
            </Row>
            {isFullscreen && (
              <SmallSquareButton icon="close" onClick={onCloseFullscreen} />
            )}
          </Row>

          <FormProvider {...form}>
            <EditAboutLotForm
              key={1}
              allFieldOptional={selectedLots.length > 1}
            />
          </FormProvider>
        </Col>
      </CardContent>
    </Card>
  );
};
