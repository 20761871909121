import { AgencyUserRole } from '../model/types/agencyUserSchema';
import { User, UserDto, NewUser } from '../model/types/userSchema';

export const mapUserFromDto = ({
  id,
  first_name,
  last_name,
  role_id,
  email,
  phone,
  position,
  agency_id,
  facebook,
  instagram,
  ok,
  vk,
  youtube,
  tariff_id,
  count_day,
  invitedBy,
  telegram,
  whatsapp,
  ref,
}: UserDto): User => ({
  id: id!,
  firstName: first_name,
  lastName: last_name,
  agencyId: agency_id,
  isAdmin: role_id === AgencyUserRole.Admin,
  tariff: tariff_id,
  phone: phone || '',
  email,
  position,
  facebook: facebook || '',
  instagram: instagram || '',
  ok: ok || '',
  vk: vk || '',
  youtube: youtube || '',
  countDay: count_day?.toString() || '',
  invitedBy,
  telegram: telegram || '',
  whatsapp: whatsapp || '',
  referral: ref || '',
});

export const mapDtoFromUser = ({
  firstName,
  lastName,
  isAdmin,
  email,
  phone,
  position,
  agencyId,
  facebook,
  instagram,
  ok,
  vk,
  youtube,
  tariff,
  invitedBy,
  telegram,
  whatsapp,
  ...rest
}: User | NewUser): UserDto => {
  const dto: UserDto = {
    id: 'id' in rest ? rest.id : undefined,
    first_name: firstName,
    last_name: lastName,
    role_id: isAdmin ? AgencyUserRole.Admin : AgencyUserRole.Employee,
    phone,
    email,
    position,
  };

  if (tariff) dto.tariff_id = tariff;
  if (agencyId) dto.agency_id = agencyId;
  if (vk) dto.vk = vk;
  if (ok) dto.ok = ok;
  if (facebook) dto.facebok = facebook;
  if (instagram) dto.instagram = instagram;
  if (youtube) dto.youtube = youtube;
  if (telegram) dto.telegram = telegram;
  if (whatsapp) dto.whatsapp = whatsapp;

  if (invitedBy) dto.invitedBy = invitedBy;

  return dto;
};
