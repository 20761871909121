import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material';
import {
  Album,
  AlbumDetails,
  AlbumsPositions,
  EditAlbumCard,
  useChangeAlbumsPositionsMutation,
  useChangePhotosPositionMutation,
  useDeleteAlbumMutation,
  useGetAlbumsQuery,
  EditAlbum,
  editAlbumZod,
  useGetAlbumProgressDates,
  CONSTRUCTION_PROGRESS_TYPE,
  albumNameOptions,
  PhotosPositions,
} from 'entities/album';
import { useVersionCheck } from 'entities/object';
import { AddPhotoButton } from 'features/photo';
import { getLabelByValue } from 'shared/helpers/getLabelByValue';
import { useConfirmationDialog } from 'shared/hooks/useConfirmationDialog';
import { useSnackbar } from 'shared/hooks/useSnackbar';
import { TextColor } from 'shared/theme/colors';
import { PhotoData } from 'shared/types';
import { ChipWithSelect } from 'shared/ui/ChipWithSelect';
import { Col } from 'shared/ui/Col';
import { EditButtonIcon, DoneIcon } from 'shared/ui/Icons';
import { RemovalConfirmationCard } from 'shared/ui/RemovalConfirmationCard';
import { Row } from 'shared/ui/Row';
import { SmallSquareButton } from 'shared/ui/SmallSquareButton';
import { HorizontalSortableList } from 'shared/ui/SortableList';
import { Spinner } from 'shared/ui/Spinner';

export const AlbumTabs: FC = () => {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const { objectId } = useParams<{ objectId: string }>();
  const [isSortMode, setIsSortMode] = useState<boolean>(false);
  const [sortedAlbums, setSortedAlbums] = useState<Album[]>([]);
  const [sortedPhotosMap, setSortedPhotosMap] = useState<
    Map<number, PhotoData[]>
  >(new Map());

  const { checkVersion, showVersionChangedPopup } = useVersionCheck(objectId);

  const [deleteAlbum] = useDeleteAlbumMutation();
  const { data: albumsData, isFetching: isFetchingAlbums } = useGetAlbumsQuery(
    {
      objectId: Number(objectId),
    },
    { skip: !objectId }
  );
  const albums = albumsData?.items;

  const checkTypeIsProgress = (album: Album) => {
    return album.type === CONSTRUCTION_PROGRESS_TYPE ? true : false;
  };

  const getAlbumId = (album: Album) => {
    if (checkTypeIsProgress(album) && activeProgressAlbumId) {
      return activeProgressAlbumId;
    }
    return album.id;
  };

  const [changeAlbumsPositions, { isLoading: isUpdatingAlbum }] =
    useChangeAlbumsPositionsMutation();
  const [changePhotosPositions, { isLoading: isUpdatingAlbumPhotos }] =
    useChangePhotosPositionMutation();
  const [activeAlbum, setActiveAlbum] = useState<Album | null>(null);
  const [activeProgressAlbumId, setActiveProgressAlbumId] = useState<
    number | undefined
  >(albums?.find((album) => checkTypeIsProgress(album))?.id);

  useEffect(() => {
    if (albums?.length) {
      const activeAlbumInAlbums = albums.find(
        (item) => item.id === activeAlbum?.id
      );
      if (activeAlbum) {
        setActiveAlbum(activeAlbumInAlbums || albums[0]);
      }

      if (!activeAlbum?.id || !activeAlbumInAlbums) {
        if (activeAlbum && checkTypeIsProgress(activeAlbum)) {
          setActiveProgressAlbumId(
            albums.find((album) => checkTypeIsProgress(album))?.id
          );
        }
        setActiveAlbum(albums[0]);
      }
    }
  }, [activeAlbum, albums]);

  const form = useForm<EditAlbum>({
    resolver: zodResolver(editAlbumZod),
    mode: 'onSubmit',
  });

  useEffect(() => {
    if (albums) {
      const newActiveAlbum = albums.find(
        (album) => album.id === activeAlbum?.id
      );
      form.reset({
        name: newActiveAlbum?.name || '',
        type: newActiveAlbum?.type || '',
        objectId: Number(objectId),
        year: newActiveAlbum?.year || '',
        month: newActiveAlbum?.month || '',
        order: newActiveAlbum?.order,
      });
    }
  }, [activeAlbum?.id, albums, form, objectId]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveAlbum(albums?.find((album) => album.id === newValue) || null);
  };

  const handleAlbumsSorted = (sortedAlbums: Album[]) => {
    setSortedAlbums(sortedAlbums);
  };

  const handlePhotosSorted = (albumId: number, sortedPhotos: PhotoData[]) => {
    setSortedPhotosMap((map) => new Map(map.set(albumId, sortedPhotos)));
  };

  const handleSaveNewOrder = async () => {
    if (!albums) {
      return;
    }

    const hasOrderChanged =
      sortedAlbums.some((album, index) => album.order !== index + 1) ||
      sortedPhotosMap.size;

    if (!hasOrderChanged) {
      setSortedAlbums([]);
      setSortedPhotosMap(new Map());
      setIsSortMode(false);
      return;
    }

    const isVersionChanged = await checkVersion();

    if (!isVersionChanged) {
      const progressAlbums = albums.filter((album) =>
        checkTypeIsProgress(album)
      );
      const firstProgressAlbumIndex = sortedAlbums.findIndex((album) =>
        checkTypeIsProgress(album)
      );
      if (firstProgressAlbumIndex > -1) {
        progressAlbums.sort((album1, album2) => {
          const yearDiff = Number(album2.year) - Number(album1.year);
          if (yearDiff !== 0) {
            return yearDiff;
          }
          return Number(album2.month) - Number(album1.month);
        });
      }

      const sortedAlbumsWithoutProgressAlbums = sortedAlbums.filter(
        (album) => album.type !== CONSTRUCTION_PROGRESS_TYPE
      );

      const newAlbumsPositions: AlbumsPositions = {
        albumsIds: (firstProgressAlbumIndex === -1
          ? sortedAlbums
          : [
              ...sortedAlbumsWithoutProgressAlbums.slice(
                0,
                firstProgressAlbumIndex
              ),
              ...progressAlbums,
              ...sortedAlbumsWithoutProgressAlbums.slice(
                firstProgressAlbumIndex
              ),
            ]
        ).map((album) => album.id),
      };

      if (newAlbumsPositions.albumsIds.length) {
        try {
          await changeAlbumsPositions({
            objectId: Number(objectId),
            albumsPositions: newAlbumsPositions,
          });

          setSortedAlbums([]);
        } catch (error) {
          console.error(error);
          showSnackbar();
        }
      }

      for (const [key, value] of Array.from(sortedPhotosMap.entries())) {
        const changedAlbum = albums.find((album) => album.id === key);
        if (changedAlbum) {
          try {
            const newPhotoIdsOrder: PhotosPositions = {
              photosIds: value.map((item) => item.id),
            };
            await changePhotosPositions({
              objectId: Number(objectId),
              albumId: changedAlbum.id,
              photosPositions: newPhotoIdsOrder,
            });

            setSortedPhotosMap(new Map());
          } catch (error) {
            console.error(error);
            showSnackbar();
          }
        }
      }
    }
    setIsSortMode(false);
  };

  const getAlbumName = (album: Album) => {
    return album.name || t(getLabelByValue(albumNameOptions, album.type));
  };

  const { getProgressYears, getProgressMonths } = useGetAlbumProgressDates({
    albums: albums,
    activeAlbum: activeAlbum,
  });

  const handleChangeProgressAlbumDate = (month?: string, year?: string) => {
    if (albums) {
      if (month) {
        const selectedYear = activeAlbum?.year;
        const filteredAlbums = albums.filter((album) => album.month === month);
        const foundAlbum = filteredAlbums?.find(
          (album) => album.year === selectedYear
        );
        if (foundAlbum) {
          setActiveProgressAlbumId(foundAlbum.id);
          setActiveAlbum(foundAlbum);
        } else {
          setActiveProgressAlbumId(filteredAlbums[0].id);
          setActiveAlbum(filteredAlbums[0]);
        }
      }

      if (year) {
        const selectedMonth = activeAlbum?.month;
        const filteredAlbums = albums.filter((album) => album.year === year);
        const foundAlbum = filteredAlbums?.find(
          (album) => album.month === selectedMonth
        );
        if (foundAlbum) {
          setActiveProgressAlbumId(foundAlbum.id);
          setActiveAlbum(foundAlbum);
        } else {
          setActiveProgressAlbumId(filteredAlbums[0].id);
          setActiveAlbum(filteredAlbums[0]);
        }
      }
    }
  };

  const {
    isShowRemoveConfirmation,
    showRemoveConfirmation,
    hideRemoveConfirmation,
    confirmRemoveAction,
  } = useConfirmationDialog({
    onConfirm: async () => {
      const isVersionChanged = await checkVersion();

      if (!isVersionChanged) {
        if (activeAlbum) {
          deleteAlbum(activeAlbum);
        }
      }
    },
  });

  const albumsTabs = useMemo(() => {
    if (albums) {
      const progressAlbumIndex = albums.findIndex((album) =>
        checkTypeIsProgress(album)
      );
      if (progressAlbumIndex === -1) {
        return albums;
      }
      const result: Album[] = [];
      let isAddedProgress = false;
      albums.forEach((album) => {
        if (checkTypeIsProgress(album) && !isAddedProgress) {
          result.push(album);
          isAddedProgress = true;
        } else if (!checkTypeIsProgress(album)) {
          result.push(album);
        }
      });
      if (
        !activeProgressAlbumId ||
        !albums.find((album) => album.id === activeProgressAlbumId)
      ) {
        setActiveProgressAlbumId(albums[progressAlbumIndex].id);
      }
      return result;
    }
    return [];
  }, [activeProgressAlbumId, albums]);

  const tabsComponents = albumsTabs.map((album) => (
    <Tab
      key={album.id}
      value={checkTypeIsProgress(album) ? activeProgressAlbumId : album.id}
      label={<Typography variant={'accentS'}>{getAlbumName(album)}</Typography>}
    />
  ));

  const handleSetSortMode = () => {
    setIsSortMode(true);
  };

  const tabContentComponents = useMemo(
    () =>
      new Map(
        albums?.map((album) => {
          return [
            album.id,
            <AlbumDetails
              key={album.id}
              album={album}
              sortMode={isSortMode}
              objectId={Number(objectId)}
              sortedPhotos={sortedPhotosMap.get(album.id) || []}
              onPhotosSort={(sortedPhotos) =>
                handlePhotosSorted(album.id, sortedPhotos)
              }
              isDataUpdating={isFetchingAlbums}
            />,
          ];
        })
      ),
    [albums, isFetchingAlbums, isSortMode, objectId, sortedPhotosMap]
  );

  return (
    <Col spacing={8}>
      {showVersionChangedPopup()}
      <Row spacing={9} wrap={false} maxWidth={1024} sx={{ m: 'auto' }}>
        {albums && albums.length > 0 && (
          <Card sx={{ width: '648px', position: 'relative' }}>
            <Col spacing={8}>
              <Box
                display="flex"
                alignItems="center"
                overflow={'auto'}
                sx={{
                  maxWidth: '580px',
                  '&::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {isSortMode ? (
                  <HorizontalSortableList
                    items={sortedAlbums.length ? sortedAlbums : albumsTabs}
                    onChange={handleAlbumsSorted}
                    renderItem={(album) => (
                      <HorizontalSortableList.Item key={album.id} id={album.id}>
                        <Box
                          onClick={() => {
                            setActiveAlbum(
                              checkTypeIsProgress(album) &&
                                activeProgressAlbumId
                                ? albums.find(
                                    (album) =>
                                      album.id === activeProgressAlbumId
                                  ) || null
                                : album
                            );
                          }}
                          sx={{
                            ':hover': {
                              cursor: 'pointer',
                            },
                            borderBottom: `3px solid ${
                              activeAlbum?.id === getAlbumId(album)
                                ? TextColor.Primary
                                : 'transparent'
                            }`,
                            transition: 'border-bottom-color 0.3s ease-in-out',
                          }}
                        >
                          <Row
                            flexWrap={'nowrap'}
                            sx={{ p: 8, pb: '20px', gap: 4 }}
                          >
                            <HorizontalSortableList.DragHandle />
                            <Typography variant={'accentS'} noWrap>
                              {getAlbumName(album)}
                            </Typography>
                          </Row>
                        </Box>
                      </HorizontalSortableList.Item>
                    )}
                  />
                ) : (
                  activeAlbum && (
                    <Tabs
                      variant="scrollable"
                      value={activeAlbum.id}
                      onChange={handleTabChange}
                      sx={{
                        flexGrow: 1,
                        maxWidth: '580px',
                        '.MuiTabs-scrollButtons.Mui-disabled': {
                          opacity: '0.3',
                        },
                      }}
                    >
                      {tabsComponents}
                    </Tabs>
                  )
                )}
                <Button
                  sx={{
                    p: 0,
                    height: '65px',
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    bgcolor: TextColor.StaticWhite,
                    '&:hover, &:active': {
                      bgcolor: 'white',
                    },
                  }}
                  onClick={() => {
                    isSortMode ? handleSaveNewOrder() : handleSetSortMode();
                  }}
                >
                  {isSortMode ? (
                    isUpdatingAlbum || isUpdatingAlbumPhotos ? (
                      <Spinner />
                    ) : (
                      <DoneIcon sx={{ color: '#616F84' }} />
                    )
                  ) : (
                    <EditButtonIcon sx={{ color: TextColor.Secondary }} />
                  )}
                </Button>
              </Box>
              <Row spacing={4} ml={8} sx={{ alignItems: 'flex-end' }}>
                {activeAlbum && !isSortMode && (
                  <FormProvider {...form}>
                    <AddPhotoButton
                      album={activeAlbum}
                      photosCount={activeAlbum?.albumImages?.length}
                    />
                  </FormProvider>
                )}

                {!isSortMode && (
                  <SmallSquareButton onClick={showRemoveConfirmation} />
                )}
                {activeAlbum && checkTypeIsProgress(activeAlbum) && (
                  <FormProvider {...form}>
                    <Row spacing={4}>
                      <ChipWithSelect
                        options={getProgressYears()}
                        label={t('album.year')}
                        name={'year'}
                        onChange={(event) =>
                          handleChangeProgressAlbumDate(
                            undefined,
                            event.target.value
                          )
                        }
                      />
                      <ChipWithSelect
                        options={getProgressMonths()}
                        label={t('album.month')}
                        name={'month'}
                        onChange={(event) =>
                          handleChangeProgressAlbumDate(
                            event.target.value,
                            undefined
                          )
                        }
                      />
                    </Row>
                  </FormProvider>
                )}
              </Row>
            </Col>
            {isShowRemoveConfirmation && activeAlbum && (
              <RemovalConfirmationCard
                sx={{ mx: 8, mt: 8, mb: 5 }}
                titleEnding={t('album.confirmDelete', {
                  name: getAlbumName(activeAlbum),
                })}
                onRemoveClick={confirmRemoveAction}
                onCancelClick={hideRemoveConfirmation}
              />
            )}
            {activeAlbum && tabContentComponents.get(activeAlbum.id)}
          </Card>
        )}
        <EditAlbumCard objectAddedAlbums={albums || []} />
      </Row>
    </Col>
  );
};
