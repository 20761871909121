import { Lot } from 'entities/chess';
import { NumberingFormatType } from 'entities/chess/model/types/lotSchema';

export const changeLotsNumbers = (
  lots: Lot[],
  numbering: string,
  formatType: NumberingFormatType
): Lot[] => {
  if (!numbering) {
    return clearNumbers(lots);
  }

  switch (formatType) {
    case NumberingFormatType.number:
      return numberFormat(lots, numbering);
    case NumberingFormatType.numberLetterNumber:
      return numberLetterNumberFormat(lots, numbering);
    case NumberingFormatType.numberNumber:
      return numberNumberFormat(lots, numbering, '-');
    case NumberingFormatType.numberSlashNumber:
      return numberNumberFormat(lots, numbering, '/');
    case NumberingFormatType.numberNumberLetter:
      return numberNumberLetterFormat(lots, numbering);
    case NumberingFormatType.letterNumberNumber:
      return letterNumberNumberFormat(lots, numbering);
    case NumberingFormatType.numberLetter:
      return numberLetterFormat(lots, numbering);
    case NumberingFormatType.numberPointNumber:
      return numberNumberFormat(lots, numbering, '.');
    case NumberingFormatType.numberUnderscoreNumber:
      return numberNumberFormat(lots, numbering, '_');
    case NumberingFormatType.letterNumber:
      return letterNumberFormat(lots, numbering);
    default:
      return lots; // Если формат не распознан, возвращаем исходные лоты.
  }
};

const clearNumbers = (lots: Lot[]): Lot[] => {
  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    return { ...lot, lotNumber: '' };
  });
};

const numberFormat = (lots: Lot[], numbering: string): Lot[] => {
  let currentNumber = parseInt(numbering, 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const updatedLot = { ...lot, lotNumber: currentNumber.toString() };
    currentNumber += 1;
    return updatedLot;
  });
};

const numberLetterNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/^(\d+)([а-яА-Яa-zA-Z])(\d+)$/);

  if (!match) throw new Error('Invalid format');

  const [_, prefix, letter, suffix] = match;

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));
  let currentPrefix = parseInt(prefix, 10);
  let currentSuffix = parseInt(suffix, 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffix, 10); // сбросить currentSuffix на начальное значение
    }
    const changedLot = {
      ...lot,
      lotNumber: `${currentPrefix}${letter}${currentSuffix}`,
    };

    currentSuffix++;

    return changedLot;
  });
};

const parseNumbering = (
  numbering: string,
  delimiter: string
): [number, number] => {
  const parts = numbering.split(delimiter);
  if (parts.length !== 2) {
    throw new Error(
      `Некорректный формат номера: ${numbering}, ожидаемый разделитель "${delimiter}"`
    );
  }

  const prefix = parseInt(parts[0], 10);
  const suffix = parseInt(parts[1], 10);

  if (isNaN(prefix) || isNaN(suffix)) {
    throw new Error(`Ошибка парсинга номера: ${numbering}`);
  }

  return [prefix, suffix];
};

const numberNumberFormat = (
  lots: Lot[],
  numbering: string,
  delimiter: string
): Lot[] => {
  let [currentPrefix, currentSuffix] = parseNumbering(numbering, delimiter);

  // Получаем минимальный этаж у первого активного лота
  const firstLot = lots.find((lot) => !lot.disabled);
  if (!firstLot || firstLot.positions.length === 0) {
    return lots; // Если нет активных лотов или позиций — возвращаем без изменений.
  }

  let currentFloor = Math.min(...firstLot.positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseNumbering(numbering, delimiter)[1]; // сброс номера
    }

    return {
      ...lot,
      lotNumber: `${currentPrefix}${delimiter}${currentSuffix++}`,
    };
  });
};

const numberNumberLetterFormat = (lots: Lot[], numbering: string): Lot[] => {
  const [prefix, suffixWithLetter] = numbering.split('/');
  let currentPrefix = parseInt(prefix, 10);

  const suffixLetterMatch = suffixWithLetter.match(/(\d+)([а-яА-Яa-zA-Z])/);
  if (!suffixLetterMatch) throw new Error('Invalid format');

  let currentSuffix = parseInt(suffixLetterMatch[1], 10);
  const letter = suffixLetterMatch[2];

  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }
    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    // Если этаж у лота увеличился по сравнению с текущим
    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentPrefix++;
      currentSuffix = parseInt(suffixLetterMatch[1], 10);
    }

    const changedLot = {
      ...lot,
      lotNumber: `${currentPrefix}/${currentSuffix}${letter}`,
    };
    currentSuffix++;

    return changedLot;
  });
};

const letterNumberNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  // Разбиваем строку на букву и две части с цифрами
  const match = numbering.match(/^([а-яА-Яa-zA-Z])(\d{1,5})-(\d{1,5})$/);
  if (!match) throw new Error('Invalid format');

  const letter = match[1];
  let currentNumber1 = parseInt(match[2], 10);
  let currentNumber2 = parseInt(match[3], 10);

  // Ищем минимальный этаж среди всех лотов
  let currentFloor = Math.min(...lots[0].positions.map((pos) => pos.floor));

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const lotMinFloor = Math.min(...lot.positions.map((pos) => pos.floor));

    // Если этаж у лота увеличился, увеличиваем номер
    if (lotMinFloor > currentFloor) {
      currentFloor = lotMinFloor;
      currentNumber1++; // увеличиваем первую часть
      currentNumber2 = 1; // сбрасываем вторую часть
    }

    const changedLot = {
      ...lot,
      lotNumber: `${letter}${currentNumber1}-${currentNumber2}`,
    };

    currentNumber2++; // увеличиваем вторую часть

    return changedLot;
  });
};

const numberLetterFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/(\d+)([а-яА-Яa-zA-Z])/);
  if (!match) throw new Error('Invalid format1');

  let currentNumber = parseInt(match[1], 10);
  const letter = match[2];

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const changedLot = {
      ...lot,
      lotNumber: `${currentNumber}${letter}`,
    };
    currentNumber++;

    return changedLot;
  });
};

const letterNumberFormat = (lots: Lot[], numbering: string): Lot[] => {
  const match = numbering.match(/^([а-яА-Яa-zA-Z])(\d+)$/);
  if (!match) throw new Error('Invalid format2');

  const letter = match[1];
  let currentNumber = parseInt(match[2], 10);

  return lots.map((lot) => {
    if (lot.disabled) {
      return lot;
    }

    const changedLot = {
      ...lot,
      lotNumber: `${letter}${currentNumber}`,
    };
    currentNumber++;

    return changedLot;
  });
};
