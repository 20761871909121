import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { Col } from 'shared/ui/Col';
import { FileUploadField } from 'shared/ui/FileUploadField';
import { Row } from 'shared/ui/Row';

export const DeveloperDocumentsEditForm: FC = () => {
  const { t } = useTranslation();

  return (
    <Col spacing={5} sx={{ width: '100%' }}>
      <Col spacing={5}>
        <Row spacing={5} equalsChildrenWidth>
          <FileUploadField
            description={t('developer.form.label.documentNalog')}
            name="documentNalogHash"
            maxFileSize={10}
          />
          <FileUploadField
            description={t('developer.form.label.documentUstav')}
            name="documentUstavHash"
            maxFileSize={10}
          />
        </Row>
        <Row spacing={5} equalsChildrenWidth>
          <FileUploadField
            description={t('developer.form.label.documentDirector')}
            name="documentDirectorHash"
            maxFileSize={10}
          />
          <FileUploadField
            description={t('developer.form.label.documentGenDirector')}
            name="documentGenDirectorHash"
            maxFileSize={10}
          />
        </Row>
        <FileUploadField
          description={t('developer.form.label.documentPermissionsApprove')}
          name="documentPermissionsApproveHash"
          maxFileSize={10}
        />
      </Col>
      <Typography variant="caption">
        {t('developer.form.label.documentSize')}
      </Typography>
    </Col>
  );
};
