import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { useAppSelector } from 'shared/hooks/hooks';
import { TextColor } from 'shared/theme/colors';
import { CheckboxField } from 'shared/ui/CheckboxField';
import { Col } from 'shared/ui/Col';
import { FileUploadField } from 'shared/ui/FileUploadField';
import { MapInput } from 'shared/ui/MapInput';
import { NumericField } from 'shared/ui/NumericField';
import { Row } from 'shared/ui/Row';
import { StyledTextField } from 'shared/ui/StyledTextField';
import { EditDeveloper } from '../../model/types/developerSchema';

export const DeveloperMainDataEditForm: FC = () => {
  const { t } = useTranslation();
  const form = useFormContext<EditDeveloper>();

  const user = useAppSelector((state) => state.profile.profile);

  const getDeveloperCoords = (): [number, number] | undefined => {
    const latitude = form.getValues('latitude');
    const longitude = form.getValues('longitude');

    if (latitude && longitude) {
      return [latitude, longitude];
    }

    return undefined;
  };

  return (
    <Col spacing={8}>
      <Row spacing={5} equalsChildrenWidth>
        <StyledTextField
          label={t('developer.form.label.name')}
          required
          name="name"
          maxLength={40}
          showCharacterHintAtRemaining={10}
        />
        <NumericField
          label={t('developer.form.label.inn')}
          name="inn"
          valueIsNumericString
          maxLength={12}
          allowNegative={false}
          decimalScale={0}
        />
      </Row>
      {user?.isNedvexUserOrAdmin && (
        <CheckboxField
          name="isHidden"
          label={t('developer.form.label.hideFromAgents')}
        />
      )}
      <StyledTextField
        label={t('developer.form.label.description')}
        name="description"
        multiline
        maxLength={3000}
        showCharacterHintAtRemaining={300}
      />
      <Col spacing={5}>
        <Row spacing={5} equalsChildrenWidth>
          <FileUploadField
            description={t('developer.form.label.logo')}
            fileType="image"
            name="logo"
            maxFileSize={10}
          />
          <></>
        </Row>
        <Typography variant={'caption'} color={TextColor.Secondary}>
          {t('developer.form.label.logoSize')}
        </Typography>
      </Col>
      <MapInput
        defaultCoords={getDeveloperCoords()}
        inputLabel={t('developer.form.label.address')}
        onChange={(value, latitude, longitude) => {
          form.setValue('address', value);
          form.setValue('latitude', latitude);
          form.setValue('longitude', longitude);
          form.trigger('address');
        }}
      />
    </Col>
  );
};
